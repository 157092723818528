import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

@Component({
    selector: 'ln-likes-views',
    templateUrl: './likes-views.component.html',
    styleUrls: ['./likes-views.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class LikesViewsComponent {
  @Input() likes?: number;
  @Input() views?: number;
  @Input() @HostBinding('class.likes-views__reverse') isReverse?: boolean;

  constructor() {}
}
