import { NgModule } from '@angular/core';
import { LikesViewsComponent } from './likes-views.component';
import { SharedModule } from '@app/shared/shared.module';

@NgModule({
  imports: [SharedModule],
  declarations: [LikesViewsComponent],
  exports: [LikesViewsComponent]
})
export class LikesViewsModule {}
